body{
  margin: 0px;
  padding: 0px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
}

/* scrollbar style start */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #555;
}

::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  background-color: #F5F5F5;
}

/* scrollbar style end */


/*  */
.allobaby_web .our-app-table .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  color: #FF626F;
  -webkit-text-stroke: 1px #000;
  /* display: inline-block; */
}

.allobaby_web .our-app-table .table td span.green{
  color: #01EB7B;
}

.allobaby_web .our-app-table .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
  color: #212529;
}

.allobaby_web .our-app-table .table-hover tbody tr:hover {
  color: #212529;
  background-color: #fff;
}

.allobaby_web h5.h5_title{
  color: #FF626F;
  -webkit-text-stroke: 1px #000;
}

.allobaby_web h4.h4_title, .allobaby_web h5.h5_title{
 font-size: 28px;
}

.allobaby_web .our-app-table .table td.border_btm{
  border-bottom: 0px solid transparent;
}

.allobaby_web .our-app-table .table td.border_top{
  border-top: 0px solid transparent;
}

.allobaby_web .our-app-table .table.table-bordered td.border_top {
  border: 0px solid transparent;
}
.allobaby_web .our-app-table .table.table-bordered td.border_btm{
  border: 0px solid transparent;
}
.allobaby_web .allobaby_ourapp .lt_line_common{
  position: absolute;
  left: 0px;
  top: 25%;
  z-index: 4;
}

.allobaby_web .allobaby_ourapp .lt_line_common.choose_lineplane {
  top: 3%;
}
/*  */

.allobaby_web {
  font-size: 16px;
}

.allobaby_web .carousel .carousel-item {
  
}

.allobaby_web .carousel .carousel-item > *:first-child {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
}

.allobaby_web .img_overlay{
  /* background: url('./../public/assets/home/img_01.webp') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

.allobaby_web .carousel .carousel-inner::after {
  /* position: absolute;
  top: -120px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 20.31%, rgba(217, 217, 217, 0) 58.33%); */
}

.allobaby_web .carousel .carousel-inner .carousel-caption {
  bottom: unset;
  text-align: left;
  width: 32%;
  left: 12%;
  top: 26%;
  z-index: 4;
}

.allobaby_web .navbar .navbar-brand img.logo{
  width: 148px;
}

.allobaby_web .navbar .navbar-brand ul{

}

.allobaby_web .navbar .navbar-brand ul li{

}

.allobaby_web a {
  color: #3A3A3A;
  text-decoration: none;
  background-color: transparent;
}

/* Navbar style start */

.allobaby_web .navbar-nav .nav-link {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .5px;
    position: relative;
}

.allobaby_web .navbar-brand img{
  width: 200px;
}

.allobaby_web .navbar.bg-white.navbar_expand {
  /* background-color: #fff!important; */
  background: linear-gradient(180deg, #fff 0%, #fff 50%, rgb(217 217 217 / 0%) 100%);
  background-color: transparent !important;
  transition: 2s all ease;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.allobaby_web .navbar.bg-white.navbar_expand.fixed-top {
  background-color: #fff!important;
  padding: 0.7rem 1rem;
}

.allobaby_web .navbar{
  padding: 1rem 1rem;
}

.allobaby_web .navbar .dropdown-item{
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 10px;
  letter-spacing: .5px;
}

.allobaby_web .navbar .dropdown-item.active, .allobaby_web .navbar .dropdown-item:active, .allobaby_web .navbar .dropdown-item:focus, .allobaby_web .navbar .dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ff5564;
}

.allobaby_web .testimonials.carousel .carousel-indicators button{
    background: url(https://fatneedle.com/assets/home/Element_12.svg) no-repeat center center; 
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    content: '';
    z-index: 0;
    height: 20px;
    width: 20px;
    border-width: 0px;
}

.allobaby_web .testimonials.carousel .carousel-indicators button.active{
   background: url(https://fatneedle.com/assets/home/Element_13.svg) no-repeat center center; 
   -webkit-background-size: contain;
   -moz-background-size: contain;
   -o-background-size: contain;
   background-size: contain;
   content: '';
   z-index: 0;
   height: 20px;
   width: 20px;
}

.allobaby_web .navbar-expand-lg .navbar-nav .nav-link.active::after{
    background: url(https://fatneedle.com/assets/home/Element_01.svg) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    content: '';
    z-index: 0;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 5px;
    bottom: 2px;
}

@media (min-width: 992px){

.allobaby_web .navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.allobaby_web .navbar-expand-lg .navbar-nav .dropdown-menu {
  padding: 0px;
}

}


/* Navbar style end */

/* Carosel style start */

.allobaby_web .rt_line {
  position: absolute;
  right: 58px;
  top: -60px;
  z-index: 9999;
  height: 100px;
}

.allobaby_web .lt_line {
  position: absolute;
  left: 0px;
  top: 31%;
  z-index: 4;
}

.allobaby_web .lt_line img{
  width: 120px;
} 

.social_icons{
  position: absolute;
  right: 50px;
  z-index: 9999;
  top: 50px;
}

.social_icons li{
  list-style: none; 
  padding: 10px 0px;
}

.social_icons img {
  width: 18px;
  height: 18px;
}

.allobaby_web .rt_line img{
  height: 100%;
  width: 100%;
 }

.allobaby_web .carousel .carousel-inner h3 {
  font-size: 48px;
  color: #3A3A3A;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.allobaby_web .carousel .carousel-inner p {
  color: #3A3A3A;
  font-size: 20px;
}

.allobaby_web .carousel .carousel-control-next, .allobaby_web .carousel-control-prev, .allobaby_web .carousel .carousel-indicators{
  display: none;
}

.allobaby_web .testimonials.carousel .carousel-indicators {
  display: grid;
  left: unset;
  right: -30px;
  justify-content: center;
  margin: 0px;
  top: 0;
  height: 100px;
}

.allobaby_web .btm_img_line{
  width: 100%;
}

.allobaby_web .btm_img_line img{
  width: 100%;
}

/* Carosel style end */

/* Testimonials start */

.allobaby_web .testimonials_bg.testimonials_home{
  background: url(https://fatneedle.com/assets/home/testimonials.webp) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background-size: 100% 100%;
}

.allobaby_web .section.testimonials_bg{
    /* background: url(https://fatneedle.com/assets/home/testimonials.webp) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 700px; */

  background: url(https://fatneedle.com/assets/home/testimonials.webp) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background-size: 100% 100%;
}

.allobaby_web .testimonials.carousel .carousel-inner::after{
  position: unset;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 20.31%, rgba(217, 217, 217, 0) 58.33%);
}

.allobaby_web .testimonials .grid_testimonials_bgimg{
    /* background: url(https://fatneedle.com/assets/home/Element_08.svg) no-repeat center center; 
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain; */
}


.allobaby_web .testimonials h3{

}

.allobaby_web .testimonials.carousel .carousel-inner h3{
    font-size: 18px;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}

.allobaby_web .testimonials.carousel .carousel-inner .carousel-caption{
  bottom: unset;
  text-align: left;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 4;
}

.allobaby_web .testimonials.carousel .carousel-inner p {
  font-size: 15px;
  line-height: 1.6;
}
/* Testimonials end */
/* About page style start */
.allobaby_web .testimonials_bg .grid_bx{
  padding: 0rem 1rem 3rem 1rem;
}
.allobaby_web .testimonials_bg .grid_bx img {
  width: 100%;
  padding: 1rem;
}
.allobaby_web .testimonials_bg .grid_bx h5 {
  font-weight: normal;
  font-size: 18px;
}

.allobaby_web .testimonials_bg.team .grid_bx h4 {
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    text-align-last: center;
    line-height: 1.5;
}

.allobaby_web .testimonials_bg.team .grid_bx img {
  width: 100%;
  padding: 1rem;
  height: 286px;
  object-fit: contain;
}

.allobaby_web .testimonials_bg .grid_bx h5 strong {
  font-size: 1.1rem;
}
.allobaby_web .testimonials_bg .grid_bx .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #ef0808;
}
.allobaby_web .testimonials_bg .grid_bx .slick-dots li button:hover:before, .allobaby_web .testimonials_bg .grid_bx .slick-dots li button:focus:before{
  color: #ef0808;
}
.allobaby_web .testimonials_bg .grid_bx .slick-dots li button:before{
  opacity: 1;
  color: #D9D9D9;
}
.allobaby_web .our_supports{
  margin-bottom: 20px;
  text-align: center;
  padding: 1rem;
}
.allobaby_web .our_supports img{
  width: 100%;
}
.allobaby_web a.btn.btn-see-more{
  background: #F1FFF5;
  border-radius: 30px;
  border: 1px solid #000000;
}
/* About page style end */
/* Section style start */

.allobaby_web .section{
 padding-top: 100px;
 padding-bottom: 100px;
}

.allobaby_web .section h3{

}

.allobaby_web .section h3 span {
  display: block;
}

.allobaby_web .section.app-feature{
  height: auto !important;
  position: relative;
}

.allobaby_web .section.app-feature .grid-content {
  padding-left: 0px;
}

.allobaby_web .section.app-feature .right_side_img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 500px;
}

.allobaby_web .section.app-feature .left_side_img{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 500px;
}

.allobaby_web .section.app-feature .right_side_img img, .allobaby_web .section.app-feature .left_side_img img {
  width: 100%;
  height: 100%;
}

.allobaby_web .section.app-feature p{
  font-size: 16px;
  line-height: 1.6;
}

.allobaby_web .grid_products {
  padding: 1rem;
}

.allobaby_web .grid_products.puct1 img{
  /* background-color: #f0f4ff; */
}

.allobaby_web .grid_products.puct2 img{
  /* background-color: #fff1fa; */
}

.allobaby_web .grid_products.puct3 img{
  /* background-color: #edfff6; */
}

.allobaby_web .grid_products img{
  width: 100%;
  border-radius: 100%;
  height: 318px;
  padding: 0rem;
  object-fit: contain;
  width: 318px;
  border: 0px solid #5e5454;
}

.allobaby_web .section.app-feature .right_side_img .grid_round {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.allobaby_web .section.app-feature .right_side_img .grid_round span {
  display: list-item;
  padding: 18px;
  list-style: none;
}

.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img {
  width: 48px;
  height: 48px;
  position: relative;
  background-color: #EAE7FF;
  border-radius: 100%;
  padding: 8px;
  cursor: pointer;
}

.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child1 {
  left: 138px;
  top: -57px;
}

.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.active{
  background-color: #f9d0ea; 
}

.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child2 {
  right: -20px;
  top: -75px;
}
.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child3 {
  right: 58px;
  top: -58px;
}
.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child4 {
    right: 75px;
    top: -18px;
}
.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child2 {
  right: -20px;
  top: -70px;
}
.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child5 {
  right: 40px;
  top: 10px;
}
.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child6 {
    right: -32px;
    top: 10px;
}
.allobaby_web .section.app-feature .right_side_img .grid_round img.child_img.child7 {
  left: 140px;
  top: -30px;
}
.allobaby_web .lt_line_common.contact_line {
  position: absolute;
  left: 0px;
  top: 13%;
  z-index: 4;
}
.allobaby_web .lt_line_common {
  position: absolute;
  left: 0px;
  top: 35%;
  z-index: 4;
}

.allobaby_web a.view_more.btn-primary {
    color: #3A3A3A;
    background-color: #f0f4ff;
    border-color: #f0f4ff;
    border-radius: 25px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 14px;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

.allobaby_web a.view_more.btn-primary:hover{
  color: #3A3A3A;
  background-color: #dee3f3;
  border-color: #dee3f3;
}

.allobaby_web a.view_more.btn-primary:not(:disabled):not(.disabled).active, .allobaby_web a.view_more.btn-primary:not(:disabled):not(.disabled):active {
  color: #3A3A3A;
  background-color: #dee3f3;
  border-color: #dee3f3;
}

.allobaby_web .lt_line_common.selfscreening{
  top: 5%;
}

.allobaby_web .lt_line_common.h_one{
top: 25%;
}
.allobaby_web .lt_line_common.h_two{
  top: 14.5%;
}
.allobaby_web .lt_line_common.h_three{
  top: 29%;
}
.allobaby_web .lt_line_common.h_four {
  top: 15%;
  right: 0;
  left: unset;
}
.allobaby_web .lt_line_common.h_four img {
  width: 200px;
}
.allobaby_web .lt_line_common.right_line{
  position: absolute;
  right: 0px;
  left: unset;
  top: 20%;
  z-index: 4;
}
.allobaby_web textarea.form-control {
  height: auto;
  border-radius: 15px;
  resize: none;
}
.allobaby_web .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 5px);
  padding: 1rem 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #3a3a3a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000000;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  background: #F1FFF5;
  border-radius: 10px;
}
.allobaby_web .section.app-feature.contactinfo p {
  font-size: 16px;
  line-height: normal;
}
.allobaby_web .section.app-feature.contactinfo .lt_line_common{
  top:17%;
}
.allobaby_web .cnt_submit{
  background: #EAE7FF;
  border-radius: 10px;
  color: #000;
  border: 1px solid #000000;
  width: 100%;
}
iframe{
  margin: 0 auto;
  display: block;
}
.allobaby_web .lt_line10percentage {
  top: 10%;
}
.allobaby_web .lt_line8percentage{
  top: 8%;
}
.allobaby_web .lt_line_common img{
  width: 110px;
} 
.allobaby_web .lt_line_common.savemom{
  top:3%
}
.allobaby_web .lt_line_common.allohms{
  top:5%;
}
.allobaby_web .grid_about.ourteam .lt_line_common {
  top: 6%;
}
.allobaby_web .grid_about .lt_line_common{
  position: absolute;
  left: 0px;
  top: 17%;
  z-index: 4;
}
.allobaby_web .grid_about .lt_line_common img{
  width: 110px;
} 
/* Section style end */
/* Download section style start */
.allobaby_web .grid-dwn-img{
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  height: 468px;
}
.allobaby_web .grid-dwn-img img{
  width: 100%;
  height: 100%;
}
.allobaby_web .logo_2{
}
.allobaby_web .logo_2 img {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
.allobaby_web .logo_2 img:last-child{
  margin-right: 0px;
}
/* Download section style end */

/* All Bloglist start */
.allobaby_web .blog_list {
  border: 1px solid #f7f7f7;
  box-shadow: 20px 20px 40px rgb(0 0 0 / 3%);
}

.allobaby_web .blog_list{

}

.allobaby_web .blog_list img {
  width: 100%;
  height: 218px;
  object-fit: contain;
}

.allobaby_web .content-part{
 padding-left: 15px;
 padding-right: 15px;
 padding-bottom: 15px;
}
.allobaby_web .content-part a.read_more.text-right {
  text-align: center !important;
  display: block;
  border-top: 1px solid #f7f7f7;
  padding-top: 15px;
}
.allobaby_web .content-part h6{
  font-size: 13px;
}
/* All Bloglist end */

/* Our customers style start */
.allobaby_web .section.customer_bx{

}

.allobaby_web .section.news_bx .slick-slide div{
  border-color: #5e5454;
}

.allobaby_web .section.news_bx .slick-slide div h5,.allobaby_web .section.news_bx .slick-slide div h6,.allobaby_web .section.news_bx .slick-slide div p,.allobaby_web .section.news_bx .slick-slide div a{
  padding: 0px .5rem;
}


.allobaby_web .section.customer_bx .slick-slide, .allobaby_web .section.news_bx .slick-slide, .allobaby_web .section.blogs_bx .slick-slide{
  padding: 0px .5rem;
}

.allobaby_web .section.customer_bx .slick-slide a, .allobaby_web a.read_more {
  color: #FF626F;
}

.allobaby_web .section.customer_bx .slick-slide p, .allobaby_web .section.news_bx .slick-slide p, .allobaby_web .section.blogs_bx .slick-slide p{
    text-align: justify;
    text-align-last: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
    height: 68px;
}

.allobaby_web .section.customer_bx .slick-slider .slick-dots li button, .allobaby_web .section.news_bx .slick-slider .slick-dots li button{
  font-size: 14px;
  color: #000;
}

.allobaby_web .section.customer_bx .slick-slide a, .allobaby_web .section.news_bx .slick-slide a.read_more {
  text-align: right;
  display: block;
}

.allobaby_web .section.customer_bx .slick-slider .slick-dots li button:before, .allobaby_web .section.news_bx .slick-slider .slick-dots li button:before{
  content: '';
}

.allobaby_web .section.customer_bx .slick-slider .slick-prev:before, .allobaby_web .section.customer_bx .slick-slider .slick-next:before,
.allobaby_web .section.news_bx .slick-slider .slick-prev:before, .allobaby_web .section.news_bx .slick-slider .slick-next:before{
  color: #FF626F;
}

.allobaby_web .section.news_bx .slick-slide .grid_bx img, .allobaby_web .section.blogs_bx .slick-slide .grid_bx img {
  width: 100%;
  min-height: 221px;
  max-height: 225px;
  object-fit: cover;
}

.allobaby_web .section.news_bx .slick-slide .grid_bx h5, .allobaby_web .section.blogs_bx .slick-slide .grid_bx h5{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: unset;
    -webkit-box-orient: vertical;
    max-height: 100%;
    min-height: auto;
    font-size: 1.1rem;
}

.allobaby_web .section.top_stories .slick-slide div div .grid_bx p, .allobaby_web .section.top_stories .slick-slide div div .grid_bx h5, .allobaby_web .section.top_stories .slick-slide div div .grid_bx a, .allobaby_web .section.top_stories .slick-slide div div .grid_bx h6{
  padding: 0px 10px;
}

.allobaby_web .section.news_bx .testimonials_bg.testimonials_home .slick-slide .grid_bx h5{
  max-height: 100%;
  min-height: auto;
}

.allobaby_web .section.news_bx .slick-slide .grid_bx h6, .allobaby_web .section.blogs_bx .slick-slide .grid_bx h6{
  font-size: 13px;
  color: #555;
  text-transform: uppercase;
}

.allobaby_web .img_right_news img.img_banner_news {
  width: 100%;
}

.allobaby_web .img_right_news {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 15%;
    width: 275px;
}

#news-c p, #news-c p p, #news-c ul, #news-c ul li{
  margin-bottom: 5px;
  list-style: none;
}

#news-c p p img {
  height: 200px !important;
  width: 200px !important;
  margin-right: 10px;
  display: block;
  float: left;
}

.allobaby_web .btn_news{
  background: #FF626F;
  border-radius: 30px;
  border: 1px solid #000000;
  padding: 1rem 2rem;
}

.allobaby_web .section.top_stories .grid_bx .card_bx {
  background: transparent;
  height: auto;
  margin-bottom: 15px;
}

.allobaby_web .section.top_stories .slick-slide div div .grid_bx {
  margin: 0rem;
  border: 0px solid #000000;
  border-radius: 10px;
}

.allobaby_web .section.top_stories .slick-slide {
  padding: 1rem 1rem;
}

.allobaby_web .section.top_stories .slick-slide p {
  color: #3a3a3a;
  text-align: justify;
  padding: 0px 0px;
}

.allobaby_web .section.top_stories .slick-slide p, .allobaby_web .section.top_stories .slick-slide a, .allobaby_web .section.top_stories .slick-slide h4{
  padding: 0px 10px 10px 10px;
}

.allobaby_web .section.blogs_bx.top_stories .testimonials_bg .grid_bx img, .allobaby_web .section.blogs_bx.top_stories .testimonials_bg .grid_bx{
  padding: 0px;
}

.allobaby_web .section.top_stories .slick-slide h4 {
  min-height: auto;
  max-height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: unset;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1rem;
}

.allobaby_web .section.top_stories .slick-slide div div .grid_bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    background: #fff;
    -webkit-box-shadow: 20px 20px 40px rgb(0 0 0 / 3%);
    box-shadow: 20px 20px 40px rgb(0 0 0 / 3%);
    border-radius: var(--tg-border-radius);
}

.allobaby_web .section.top_stories .slick-slider .slick-next:before, .allobaby_web .section.top_stories .slick-slider .slick-prev:before{
  color: #000;
}

/* Our customers style end  */

/* Our App */

.allobaby_web .app_features {

}

.allobaby_web .app_features li{
  color: #FF626F;
  font-size: 18px;
  line-height: 1.6;
}

.allobaby_web .grid_plan{
  position: relative;
}

.allobaby_web .grid_plan ul{
  position: relative;
  z-index: 1;
  padding-left: 0px;
  margin-bottom: 0px;
}

.allobaby_web .grid_plan ul li {
  list-style: none;
  padding: 5px 0px;
  font-size: 16px;
  text-align: center;
  padding-left: 0px;
}

.allobaby_web .grid_plan ul li span{
  padding-right: 5px;
}

.allobaby_web .grid_plan h4 {
  z-index: 1;
  position: relative;
  font-weight: 500;
  text-align: center;
  color: #FF626F;
  -webkit-text-stroke: 1px #000;
}

.allobaby_web .grid_plan h1{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
}

.allobaby_web .section h3 span.border-stock {
  color: #FF626F;
  -webkit-text-stroke: 1px #000;
  display: inline-block;
}

.allobaby_web .grid_plan h4:before {
  /* content: '';
  position: absolute;
  -webkit-text-stroke: 2px #000;
  left: 0;
  top: 0;
  z-index: -1; */
}

.allobaby_web .grid_plan img.title_plan {
  width: 100px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: block;
}

.allobaby_web .grid_plan img.bg_plan{
  width: 100%;
  position: absolute;
}

.allobaby_web .grid_plan{
  background: url(https://fatneedle.com/assets/about/oursupports.png) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 496px;
}

.allobaby_web .btn.btn-click-more {
  background: #EAE7FF;
  border-radius: 30px;
  border: 1px solid #000000;
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
}

.allobaby_web .lt_line_common.app_featu_line{
  top: 12%;
}

.allobaby_web .lt_line_common.choose_lineplane{
  top: 8.5%;
}
/* Our App */

/* Blog details */

.allobaby_web .blog_img{
  width: 100%;
  height: 480px;
}

.allobaby_web .blog_img img{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

/* Blog details */

/* Footer style start */
.allobaby_web .footer_main {
  /* background: url(https://fatneedle.com/assets/footer.webp) no-repeat center center;
  background-size: cover;
  height: 500px; */
  
  background: url(https://fatneedle.com/assets/footer.webp) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background-size: 100% 100%;
}
.allobaby_web .footer_main ul{
  list-style: none;
  padding-left: 0px;
}
.allobaby_web .footer_main ul li{
  padding: 5px 0px;
  font-size: 14px;

}
.allobaby_web .footer_main li a{
   transition: .5ms;
}
.allobaby_web .footer_main li a:hover{
  color: #000000;
}
.allobaby_web .footer_main .footer{
  display: unset;
  width: 100%;
}

.allobaby_web .footer_main ul.social-icon{

}

.allobaby_web .footer_main ul.social-icon li {
  display: inline-block;
  padding: 0px 15px;
}

.allobaby_web .footer_main ul.social-icon li:first-child{
  padding-left: 0px;
}

.allobaby_web .footer_main ul.social-icon li:last-child{
  padding-right: 0px;
}

.allobaby_web .footer_main ul.social-icon li img {
  width: 18px;
  height: 18px;
  opacity: .8;
}

.allobaby_web .footer_main ul.social-icon li img:hover{
  opacity: 1;
}

/* Footer style end */
/* Responsive UI start */
/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 1280px) {

.allobaby_web .lt_line_common img {
    width: 75px;
}

.allobaby_web .grid_about .lt_line_common{
  top: 24.5%;
}

.allobaby_web .grid_about .lt_line_common img {
  width: 75px;
}

.allobaby_web .grid_about.ourteam .lt_line_common img {
  width: 75px;
}

.allobaby_web .grid_about.ourteam .lt_line_common {
  top: 24.5%;
}

.allobaby_web .lt_line_common.lt_line8percentage {
  top: 7%;
}

.allobaby_web .lt_line_common.lt_line10percentage {
  top: 10%;
}

.h3, h3 {
  font-size: 1.5rem;
}

.allobaby_web .lt_line_common {
  top: 18.5%;
}

.allobaby_web .section {
  padding-bottom: 90px;
  padding-top: 90px;
}

.allobaby_web .img_right_news {
  top: 14%;
}

.allobaby_web .section.grid_about.top_stories .lt_line_common{
  top: 15%;
}

.allobaby_web .section.app-feature.contactinfo .lt_line_common {
  top: 14%;
}

.allobaby_web .lt_line_common.contact_line{
  top: 11%;
}

}

@media screen and (max-device-width: 640px) {

.allobaby_web .navbar-brand img {
    width: 140px;
}

.allobaby_web .navbar-toggler {
  border: 1px solid #000;
}

.allobaby_web .carousel .carousel-inner h3 {
  font-size: 16px;
  text-transform: capitalize;
}

.allobaby_web .carousel .carousel-inner p {
  font-size: 14px;
}

.allobaby_web .lt_line img {
  width: 52px;
}

.allobaby_web .carousel .carousel-inner .carousel-caption {
  bottom: unset;
  text-align: left;
  width: 100%;
  left: 0;
  top: 0%;
  z-index: 4;
  padding: 1rem;
}

.social_icons {
  position: absolute;
  right: 4px;
  z-index: 4;
  top: 0px;
}

.social_icons img {
  width: 15px;
  height: 15px;
}

.social_icons li {
  list-style: none;
  padding: 5px 0px;
}
.allobaby_web .navbar-toggler-icon {
  background-image: none;
}
.allobaby_web .navbar-toggler-icon::before {
  font-family: FontAwesome;
  content: "\f0c9"; /* fa-bars, fa-navicon */
}
.allobaby_web .section.app-feature .right_side_img{
  display: none;
}

.allobaby_web .lt_line_common, .allobaby_web .lt_line, .allobaby_web .rt_line{
  display: none;
}

.allobaby_web .navbar-toggler-icon {
  width: 1em;
  height: 1.1em;
}

.allobaby_web .section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.allobaby_web .grid_plan {
  height: 510px;
  margin-bottom: 20px;
}

.allobaby_web .app_features li, .allobaby_web .section.app-feature p {
  font-size: 14px;
  text-align: justify;
  text-align-last: left;
}

.allobaby_web .img_right_news {
  bottom: 0;
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
}
.allobaby_web .section.app-feature.contactinfo p {
  font-size: 14px;
  line-height: normal;
  text-align: left;
}

.allobaby_web .footer_main .container{
padding: 0px;
}

.h3, h3 {
  font-size: 1.2rem;
}

.allobaby_web .testimonials.carousel .carousel-inner .carousel-caption{
  position: relative;
  right: 0;
}

.allobaby_web .testimonials.carousel .carousel-indicators {
  display: block;
  right: 0;
  justify-content: center;
  margin: 0px;
  top: unset;
  height: auto;
  text-align: center;
  left: 0;
}

.allobaby_web .footer_main {
   background-color: #fff2f2;
}

.allobaby_web .section h3 span {
  display: unset;
}

.allobaby_web .footer_main ul li {
  padding: 5px 0px;
  font-size: 13px;
}

.allobaby_web .footer_main, .allobaby_web .testimonials_bg.testimonials_home {
  padding-top: 30px;
  padding-bottom: 30px;
}

.allobaby_web .footer_main {
  background-color: #fff2f2 !important;
  background: unset;
}

.allobaby_web .testimonials_bg.testimonials_home {
  background-color: #f0f4ff !important;
  background: unset;
}

.allobaby_web .footer_main .footer img{
  width: 120px;
}

p{
  font-size: 14px;
}

.slick-prev {
  left: 0px;
}

.slick-next {
  right: 0px;
}

.allobaby_web .navbar-expand-lg .navbar-nav .nav-link.active:after {
  bottom: 2px;
  content: "";
  height: 11px;
  left: 1px;
  position: absolute;
  width: 11px;
  z-index: 0;
}

.allobaby_web iframe{
  width: 100%;
  height: 100%;
}

.allobaby_web a.navbar-brand{
    display: unset;
    float: right;
    margin-right: 0px;
    position: absolute;
    right: 18px;
    top: 5px;
}

.allobaby_web button[aria-controls="responsive-navbar-nav"].navbar-toggler{
  display: block;
  float: left;
  position: absolute;
  left: 18px;
  top: 15px;
}

.allobaby_web .navbar.bg-white.navbar_expand {
  background: linear-gradient(180deg, #fff 0%, #fff 50%, rgb(255 255 255) 100%);
  position: fixed;
}

.allobaby_web .navbar.bg-white.navbar_expand .navbar-collapse.collapse.show{
  position: relative;
  top: 15px;
}

.allobaby_web .navbar {
  padding: 2rem 1rem;
}

.allobaby_web .main-slider{
  margin-top: 65px;
}

.allobaby_web .navbar.bg-white.navbar_expand.fixed-top {
  background-color: #fff!important;
  padding: 2rem 1rem;
}

.allobaby_web .navbar-nav .nav-link {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: .5px;
  position: relative;
}

.allobaby_web .section.app-feature .left_side_img, .social_icons{
  display: none;
}

.allobaby_web .section.app-feature ul li{
  font-size: 14px;
}

.allobaby_web .testimonials_bg.team .grid_bx h4{
  font-size: 14px;
}

}